import React from "react";
import styled from "styled-components";

import Container from "../styles/Container";

const OuterContainer = styled.div`
  background-color: ${(p) => p.theme.greyishBlue};
  color: white;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const Heading = styled.h1`
  font-size: 25px;
  line-height: 33px;
  font-weight: 900;
  color: white;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 40px;
    line-height: 54px;
  }
`;

const SubHeading = styled.p`
  font-size: 18px;
  line-height: 25px;
  padding-top: 16px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 22px;
    line-height: 32px;
  }
`;

const HeroHeading = ({ heading, subheading }) => {
  return (
    <OuterContainer>
      <Container>
        {heading && <Heading>{heading}</Heading>}
        {subheading && <SubHeading>{subheading}</SubHeading>}
      </Container>
    </OuterContainer>
  );
};

export default HeroHeading;
