import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import { RichText, Date } from "prismic-reactjs";
import get from "lodash.get";

import getDate from "../utils/getDate";

import Container from "../styles/Container";
import HeroHeading from "../components/HeroHeading";

import searchIcon from "../images/search.svg";
import triangleIcon from "../images/triangle.svg";
import HelmetInfo from "../components/HelmetInfo";

const LatestHeading = styled.h1`
  margin-top: 37px;
  padding-bottom: 16px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  border-bottom: 1px solid ${(p) => p.theme.lightGrey};
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-top: 50px;
    padding-bottom: 22px;
    /* font-size: 16px; */
    /* line-height: 24px; */
  }
`;

const NewsContainer = styled.div`
  padding-top: 37px;
  padding-bottom: 5px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 50px;
    padding-bottom: 10px;
  }
`;

const Article = styled.div`
  margin-bottom: 32px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 40px;
  }
`;

const ArticleTitle = styled.h1`
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 20px;
    line-height: 34px;
  }
  :hover {
    text-decoration: underline;
  }
`;

const ArticleTop = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4px;
`;

const DateContainer = styled.div`
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 15px;
  }
`;

const Division = styled.div`
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 15px;
  }
`;

const Vertibar = styled.div`
  margin-left: 10px;
  margin-right: 8px;
  font-size: 12px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 13px;
  }
`;

const FilterContainer = styled.div`
  padding-top: 37px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
  }
`;

const Filter = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 0px;
    width: calc((100% / 3) - 15px);
  }
  ::before {
    content: "";
    background-image: url(${triangleIcon});
    background-size: contain;
    position: absolute;
    right: 12px;
    top: 16px;
    width: 18px;
    height: 13px;
    pointer-events: none;
  }
`;

const SearchFilter = styled.div`
  position: relative;
  width: 100%;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc((100% / 3) - 15px);
  }
  ::before {
    content: "";
    background-image: url(${searchIcon});
    background-size: contain;
    position: absolute;
    right: 10px;
    top: 12px;
    width: 20px;
    height: 20px;
    pointer-events: none;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 12px 12px;
  border: none;
  border-radius: 5px;
  background-color: ${(p) => p.theme.lightGrey};
  font-size: 15px;
  line-height: 19px;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 2px;
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;
  :focus {
    border: 2px solid ${(p) => p.theme.greyishBlue};
    padding: 10px 10px;
  }
  ${(p) => p.value === "all" && `color: ${p.theme.midGrey};`}
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 12px 38px 12px 12px;
  border: none;
  border-radius: 5px;
  background-color: ${(p) => p.theme.lightGrey};
  font-size: 15px;
  line-height: 19px;
  font-weight: 900;
  letter-spacing: 2px;
  ::placeholder {
    text-transform: uppercase;
    color: ${(p) => p.theme.midGrey};
  }
  outline: none;
  :focus {
    border: 2px solid ${(p) => p.theme.greyishBlue};
    padding: 10px 36px 10px 10px;
  }
`;

const News = ({ data, prismic }) => {
  const [selectedYear, setSelectedYear] = useState("all");
  const [selectedDivision, setSelectedDivision] = useState("all");
  const [searchInput, setSearchInput] = useState("");

  const getArticles = get(data, "allPrismicNewsArticle.edges");
  const [allArticles, setAllArticles] = useState(getArticles);

  const searchTerms = searchInput.split(" ");
  const searchTermPatterns = searchTerms.map((term) => new RegExp(term, "i"));

  let distinctYears = undefined;
  let distinctDivisions = undefined;
  if (Array.isArray(allArticles)) {
    const years = allArticles.map((article) => {
      const date = Date(article.node.data.date);
      const year = date && date.getFullYear && date.getFullYear();
      return year;
    });
    const divisions = allArticles
      .map((article) => {
        return article.node.data.division;
      })
      .filter((article) => article !== null);
    distinctYears = [...new Set(years)];
    distinctYears.sort((a, b) => b - a);
    distinctDivisions = [...new Set(divisions)];
    distinctDivisions.sort();
  }

  return (
    <>
      <HelmetInfo page_data={data.prismicNewsIndex.data} />
      <HeroHeading
        heading={data.prismicNewsIndex.data.heading}
        subheading={data.prismicNewsIndex.data.subheading}
      />
      <Container>
        <FilterContainer>
          <Filter>
            <Select
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
            >
              <option value="all">All Years</option>
              {distinctYears &&
                distinctYears.map((year, index) => {
                  return (
                    <option key={index} value={year}>
                      {year}
                    </option>
                  );
                })}
            </Select>
          </Filter>
          <Filter>
            <Select
              value={selectedDivision}
              onChange={(e) => setSelectedDivision(e.target.value)}
            >
              <option value="all">All Divisions</option>
              {distinctDivisions &&
                distinctDivisions.map((divisions, index) => {
                  return (
                    <option key={index} value={divisions}>
                      {divisions}
                    </option>
                  );
                })}
            </Select>
          </Filter>
          <SearchFilter>
            <SearchInput
              placeholder="Search"
              type="text"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </SearchFilter>
        </FilterContainer>
      </Container>
      {data.prismicNewsIndex.data.subsubheading && (
        <Container>
          <LatestHeading>
            {data.prismicNewsIndex.data.subsubheading}
          </LatestHeading>
        </Container>
      )}
      <Container>
        <NewsContainer>
          {allArticles &&
            allArticles.map((article, index) => {
              const fullDate = Date(article.node.data.date);
              const year = fullDate && fullDate.getFullYear();
              if (selectedYear !== "all" && year !== Number(selectedYear))
                return null;
              if (
                selectedDivision !== "all" &&
                article.node.data.division !== selectedDivision
              )
                return null;
              let found = true;
              searchTermPatterns.forEach((term) => {
                if (!article.node.data.title.text.match(term)) {
                  found = false;
                }
              });
              return (
                found && (
                  <Article key={index}>
                    <ArticleTop>
                      {fullDate && (
                        <DateContainer>{getDate(fullDate)}</DateContainer>
                      )}
                      {article.node.data.division && (
                        <>
                          <Vertibar>|</Vertibar>
                          <Division>{article.node.data.division}</Division>
                        </>
                      )}
                    </ArticleTop>
                    {article.node.data.title.text && (
                      <Link to={`/articles/${article.node.uid}`}>
                        <ArticleTitle>
                          {article.node.data.title.text}
                        </ArticleTitle>
                      </Link>
                    )}
                  </Article>
                )
              );
            })}
        </NewsContainer>
      </Container>
    </>
  );
};

export default News;

export const query = graphql`
  query NewsIndexQuery {
    prismicNewsIndex {
      data {
        heading
        subheading
        subsubheading
      }
    }
    allPrismicNewsArticle(sort: { fields: data___date, order: DESC }) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            date
            division
          }
        }
      }
    }
  }
`;
